.navbar-brand {
  width: 20%;
}
.navbar-brand img {
  width: 100%;
}
.navbar {
  color: white;
  background-color: rgba(0, 0, 0);
}
.nav-link {
  margin-right: 15px;
  cursor: pointer;
}
.nav-item.active .nav-link {
  border-bottom: 2px solid white;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9);
}

.passes .nav-link {
  border: 2px solid white;
}
.passes:hover .nav-link,
.passes:hover a {
  color: black !important;
  background-color: white;
}

.scrolled {
  background-color: #000000;
  box-shadow: 0px 2px 8px 7px rgba(255, 255, 255, 0.15);
}

.form-control:focus {
  border-color: yellowgreen;
  border-width: 2px;
  box-shadow: none;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 50%;
  }
  .navbar-nav {
    background-color: #000000;
  }
}

#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: yellowgreen; /* Black background color */
  color: #000000; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.capital {
  color: yellowgreen;
  font-size: 32px;
}

.small {
  color: white;
  font-size: 25px;
}

@media (max-width: 435px) {
  .capital {
    color: yellowgreen;
    font-size: 25px;
  }

  .small {
    color: white;
    font-size: 18px;
  }
}
