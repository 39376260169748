@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  color: white;
  background-color: rgb(30, 30, 30);
  overflow: overlay;

  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' opacity='0.5' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E"); */
}
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  background-color: rgba(225, 225, 225, 0.2);
}
::-webkit-scrollbar-button {
  width: 10px;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(225, 225, 225, 0.3);
}

.modal {
  background-color: #00000088;
  /* transition: background-color 0.15s linear; */
}

.modal-content {
  background-color: #111;
  border-radius: 0.5rem;
  border: 0.1rem solid #bfbfbf;
  margin-bottom: 10rem;
}

.modal-header,
.modal-footer {
  border: none;
}

.close:focus {
  outline: none;
}

.btn.btn-telegram {
  background-color: #48a9e1;
  color: black;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.yarl__slide_image {
  max-width: none !important;
  max-height: none !important;
  height: 90% !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
