footer {
  background-color: #0b0b0b !important;
  margin-top: 40px;
}
.footer {
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
