.ludwig1 {
  /* position: fixed; */
  font-size: 3.2rem;
  line-height: 1em;
  font-weight: 100;
  margin-bottom: 2rem;
  margin-top: 3rem;
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
  /* top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 100 !important; */
}
.ludwig1:before,
.ludwig1:after {
  content: "\201C";
  font-family: "Sanchez";
  color: yellowgreen;
}

.ludwig1:after {
  content: "\201D";
}
.ludwig2 {
  /* position: fixed; */
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 100;
  margin-bottom: 2rem;
  margin-top: 3rem;
  /* top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 100 !important; */
}

.ludwig {
  /* position: fixed; */
  font-size: 1.1rem;
  line-height: 1em;
  font-weight: 100;
  /* top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 100 !important; */
}
.ludwig:before,
.ludwig:after {
  font-family: "Sanchez";
  color: yellowgreen;
}

.banner {
  width: 90%;
  padding: 20px;
  border-radius: 30px;
  margin-bottom: 10px;
}

.details {
  font-size: larger;
}

@media screen and (max-width: 830px) {
  .ludwig1 {
    font-size: 1.9rem;
    z-index: 1 !important;
  }
  .ludwig2 {
    font-size: 1.2rem;
    z-index: 1 !important;
    /* padding: 5px; */
  }
  .ludwig {
    font-size: 1rem;
    z-index: 1 !important;
  }
}

@media screen and (max-width: 991px) {
  .details {
    padding: 10px;
    font-size: medium;
  }
}
@media screen and (max-width: 480px) {
  .banner {
    width: 100%;
    padding: 5px;
    border-radius: 20px;
  }
}
